window.addEventListener('load', () => {

  // 오직 아이폰 / 패드로만!! sapari X
  const isIOSPlatform = /iPhone|iPad|iPod/i.test(navigator.platform)
  // 오직 아이폰 아닌 다른 기기(pc X)로만
  //const otherPlatform = /Linux/i.test(navigator.platform)

  const isChrome = /Chrome/i.test(window.navigator.userAgent)


  let nh = window.innerHeight;
  if (document.querySelector('.main-text')) {
    document.querySelector('.main-text').style.height = nh + 'px';
  }

  let urlLink = "https://apps.apple.com/kr/app/%EC%95%84%EC%9D%B4%ED%91%B8%EB%93%9C-ifood/id6443722437";

  // 아이폰이 아닌 모든 웹/앱
  if(isChrome === true && isIOSPlatform != true) {
    urlLink = "https://play.google.com/store/apps/details?id=kr.dif.ifood.ifood";

    // 메인페이지 높이 조절(사파리X)
    if (document.querySelector('.main-text')) {
      document.querySelector('.main-text').style.height = nh + 'px';
    }
  }
  else if (isIOSPlatform === true) {
    urlLink = "https://apps.apple.com/kr/app/%EC%95%84%EC%9D%B4%ED%91%B8%EB%93%9C-ifood/id6443722437";
  }

  let linkBtn = document.querySelectorAll('.iFoodApp');
  for(let i=0; i<linkBtn.length; i++) {
    linkBtn[i].setAttribute('href', urlLink);
  }

  console.log('custom.fed.js');

  Utils.init();
  setHeaderEvent();

});

/**
 * 공통함수
 */
const Utils = {
  /**
   * Set default event
   */
  init: () => {
    Utils.setDatepicker();
    Utils.setTableCheckbox();
    Utils.setValidation();
  },
  /**
   * Set Datepicker
   */
  setDatepicker() {
    let $datepicker = $('.datepicker');
    $datepicker.datepicker({ dateFormat: 'yy년 m월 d일' });
    $datepicker.datepicker().datepicker('setDate', 'today');
  },
  /**
   * Set Table Checkbox
   */
  setTableCheckbox() {
    $(document).on('change', '.ta-table-wrap input.all', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $eachCheckboxList = $tableWrap.find('input.each');
      $eachCheckboxList.prop('checked', $(e.target).prop('checked'));
    });
    $(document).on('change', '.ta-table-wrap input.each', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $allCheckbox = $tableWrap.find('input.all');
      let $eachLength = $tableWrap.find('input.each').length;
      let $eachCheckedLength = $tableWrap.find('input.each:checked').length;
      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
  },
  /**
   * Set Validation
   */
  setValidation() {
    let elements = document.querySelectorAll('input, textarea');
    for (let i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity(' ');
        if (!e.target.validity.valid) {
          elements[i].classList.add('is-invalid');
          if (e.target.type == 'file') {
            // label.classList.remove('btn-primary');
            // label.classList.add('btn-danger');
          }
        } else {
          if (e.target.type != 'file') {
            elements[i].classList.remove('is-invalid');
          } else {
            // label.classList.add('btn-primary');
            // label.classList.remove('btn-danger');
          }
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity(' ');
      };
      if ($(elements[i]).attr('required')) {
        elements[i].focusout = () => {
          // elements[i].classList.remove('is-valid');
          elements[i].classList.remove('is-invalid');
          if (elements[i].value == null || elements[i].value == '') {
            elements[i].classList.add('is-invalid');
          } else {
            // elements[i].classList.add('is-valid');
          }
        };
      }
    }
  },
  /**
   * Set Custom Select
   */
  setCustomDropdownSelect() {
    let $customSelectWrap = $('.ta-custom-dropdown-select-wrap');
    $(document).off('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item');
    $(document).on('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item', (e) => {
      let $this = $(e.target);
      let $originalSelect = $this.parents('.ta-custom-dropdown-select-wrap').find('select');
      let $originalOptionList = $originalSelect.find('option:not([value="_placeholder"]');
      let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
      let $btn = $this.parents('.custom-dropdown-select').find('.btn');
      $originalOptionList.eq($index).prop('selected', true);
      $btn.text($this.text());
      $btn.val($this.attr('data-value'));
      $btn.parent().removeClass('dropdown-placeholder');
      $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
      $this.addClass('active');
    });
    $customSelectWrap.each((index, el) => {
      let $originalSelect = $(el).find('select');
      let $originalOptgroupList = $originalSelect.find('optgroup');
      let $originalOptionList = $originalSelect.find('option');
      let isPlaceholder = false;



      if ($(el).find('.custom-dropdown-select').length <= 0) {
        let newSelect = `
        <div class="dropdown custom-dropdown-select">
        <button class="btn dropdown-toggle" type="button" id="${$originalSelect.attr('id')}Dropdown" data-toggle="dropdown" aria-expanded="false" value="${$originalSelect.val()}">
        선택해주세요.
        </button>
        <div class="dropdown-menu" aria-labelledby="${$originalSelect.attr('id')}Dropdown">
        `;
        if ($originalOptgroupList.length > 0) {
          for (let i = 0; i < $originalOptgroupList.length; i++) {
            $originalOptionList = $originalOptgroupList.eq(i).find('option');
            newSelect += `
            <div class="dropdown-optgroup">
            <h6>${$originalOptgroupList[i].label}</h6>
            `;
            for (let j = 0; j < $originalOptionList.length; j++) {


              if ($originalOptionList[j].value == '_placeholder') {
                isPlaceholder = true;
              } else {
                newSelect += `
                <a href="javascript:void(0)"
                class="dropdown-item ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                data-value="${$originalOptionList[j].value}">${$originalOptionList[j].text}</a>
                `;
              }
            }
            newSelect += '</div>';
          }
        } else {
          for (let i = 0; i < $originalOptionList.length; i++) {
            
            // 만약 option value에 link가 걸려있는 link-select이라면 a에 link값 넣기
            let linkSelWrap = $originalOptionList.prevObject.prevObject.hasClass('link-select');
            let linkSel = linkSelWrap ? '"' + $originalOptionList[i].value + '"' : "javascript:void(0)";

            if ($originalOptionList[i].value == '_placeholder') {
              isPlaceholder = true;
            } else {
              newSelect += `
              <a href=${linkSel}
              class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
              data-value="${$originalOptionList[i].value}">${$originalOptionList[i].text}</a>
              `;
            }
          }
        }
        newSelect += `
        </div>
        </div>
        `;
        $originalSelect.after(newSelect);

        if ($(el).find('a.dropdown-item.active').length > 0) {
          $('#' + $originalSelect.attr('id') + 'Dropdown').text($(el).find('a.dropdown-item.active').text());
        } else {
          if (isPlaceholder) {
            $(el).find('.custom-dropdown-select').addClass('dropdown-placeholder');
          }
        }
      }
    });
  },
  /**
   * Start Loading
   */
  startLoading() {
    let wrapElmnt = document.createElement('div');
    wrapElmnt.classList.add('spinner-wrap');
    let loadingElmnt = document.createElement('div');
    loadingElmnt.classList.add('spinner-border');
    let span = document.createElement('span');
    span.classList.add('sr-only');
    span.innerHTML = 'Loading...';
    loadingElmnt.appendChild(span);
    wrapElmnt.appendChild(loadingElmnt);

    let layoutWrap = document.getElementsByClassName('layout-wrap')[0];
    let body = document.getElementsByTagName('body')[0];
    layoutWrap.style.opacity = 0;
    body.style.overflow = 'hidden';
    body.appendChild(wrapElmnt);
  },
  /**
   * End Loading
   */
  endLoading() {
    let layoutWrap = document.getElementsByClassName('layout-wrap')[0];
    layoutWrap.style.opacity = 1;
    let wrapElmnt = document.getElementsByClassName('spinner-wrap')[0];
    wrapElmnt.style.opacity = 0;
    let body = document.getElementsByTagName('body')[0];
    body.style.overflow = 'auto';
    setTimeout(function () {
      wrapElmnt.remove();
    }, 300);
  },
  /**
   * Ajax
   * ex) Utils.request('GET', 'test.json', null, (data) => { console.log(data) });
   * @param {String} type
   * @param {String} url
   * @param data
   * @param {Function} func
   */
  request(type, url, data, func) {
    $.ajax({
      type,
      url,
      data,
      success: (data) => {
        func(data);
      },
      error: (xhr, status, error) => {
        console.log(`status: ${status}\nmessage: ${error}`);
      },
    });
  },
  /**
   * Number to KRW format
   * ex) 1000000 -> 1,000,000
   * @param {Number} value
   * @returns {String}
   */
  KRWFormatter(value) {
    if (value != '' && value != null && typeof value == 'number') {
      value = String(value)
        .replace(/[^\d]+/g, '')
        .replace(/(^0+)/, '')
        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    } else {
      value = 0;
    }
    return value == '' ? 'NaN' : value;
  },
  /**
   * Get input[type=file] detail
   * @param {Element} elmnt
   * @returns {Object}
   */
  getFileDetail(elmnt) {
    //파일 경로.
    let filePath = elmnt.value;
    //전체경로를 \ 나눔.
    let filePathSplit = filePath.split('\\');
    // 파일 전체명
    let originalFileName = filePathSplit[filePathSplit.length - 1];
    //파일확장자 앞 .의 index
    let lastDot = originalFileName.lastIndexOf('.');
    //파일명 : .으로 나눈 앞부분
    let fileName = originalFileName.substring(0, lastDot);
    //파일 확장자 : .으로 나눈 뒷부분
    let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length).toLowerCase();
    //파일 크기
    let fileSize = elmnt.files[0].size;

    let object = {
      originalName: originalFileName,
      name: fileName,
      ext: fileExt,
      size: fileSize,
    };

    return object;
  },
  /**
   * Byte to size
   * return ex) 5 GB
   * @param {Number} byte
   * @returns {String}
   */
  byteFormatter(byte) {
    let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    if (byte == 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
    return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
  },
  /**
   * Set date format
   * @param {String} date
   * @returns {Object}
   */
  dateFormatter(date) {
    if ((date == '' || date == null) && typeof date == 'string') {
      return '';
    }
    const addZero = (num, digits) => {
      var zero = '';
      num = num.toString();

      if (num.length < digits) {
        for (var i = 0; i < digits - num.length; i++) {
          zero += '0';
        }
      }
      return zero + num;
    };

    let newDate = new Date(date);

    let yyyy = newDate.getFullYear();
    let mm = addZero(newDate.getMonth() + 1, 2);
    let m = newDate.getMonth() + 1;
    let dd = addZero(newDate.getDate(), 2);
    let d = newDate.getDate();

    let object = {
      slash: yyyy + '/' + mm + '/' + dd,
      dot: yyyy + '.' + mm + '.' + dd,
      dash: yyyy + '-' + mm + '-' + dd,
      word: yyyy + '년 ' + m + '월 ' + d + '일',
    };

    return object;
  },
};

function setHeaderEvent() {
  const $btnMobileMenu = $('.navbar-nav.mobile .menu');
  const $btnMobileClose = $('.navbar-nav.mobile .close');
  const $mobileMenu = $('.navbar.mobile');
  const $navItem = $('.layout-header .navbar-nav .nav-item');
  const $navLink = $('.navbar-brand, .layout-header .navbar-nav li > a');

  // #region mobile menu event
  $btnMobileMenu.on('click', () => {
    $('.layout-header').append('<div class="header-backdrop modal-backdrop fade"></div>');
    $mobileMenu.addClass('active');
    $mobileMenu.addClass('visible');
    $('.layout-header').addClass('open-moblie');
    setTimeout(() => {
      $('.header-backdrop').addClass('show');
    }, 0);
    $btnMobileClose.css('display', 'block');
    $btnMobileMenu.css('display', 'none');
  });
  $btnMobileClose.on('click', () => {
    $('.header-backdrop').removeClass('show');
    $mobileMenu.removeClass('active');
    $('.layout-header').removeClass('open-moblie');
    setTimeout(() => {
      $('.header-backdrop').remove();
      $mobileMenu.removeClass('visible');
    }, 0);
    $btnMobileClose.css('display', 'none');
    $btnMobileMenu.css('display', 'block');
  });
  //$(document).on('click', '.header-backdrop, .btn-navbar-close', () => {
  //  $('.header-backdrop').removeClass('show');
  //  $mobileMenu.removeClass('active');
  //  setTimeout(() => {
  //    $('.header-backdrop').remove();
  //    $mobileMenu.removeClass('visible');
  //  }, 300);
  //});
  // #endregion mobile menu event
  // #region tab event
  $navItem.on('focusin', function () {
    removeMenuActive();
    //$(this).addClass('active');
  });
  //$navItem.on('click', function () {
  //  $navItem.removeClass('active-only');
  //  $(this).addClass('active-only');
  //  $(this).removeClass('active');
  //});
  $navLink.on('focusin', function () {
    removeMenuActive();
  });
  $(document).on('mousedown', function () {
    removeMenuActive();
  });
  $('.layout-main, .layout-footer').on('focusin', function () {
    removeMenuActive();
  });
  function removeMenuActive() {
    $('.layout-header .navbar-nav .nav-item').removeClass('active');
  }
  // #endregion tab event

  // header nav event
  $(".layout-wrap.homepage").on("mousewheel", function() { navScroll(); });
  //$(function() { navScroll();});
  $(".layout-wrap.homepage").on("touchmove", function() { navScroll(); });
  window.addEventListener('scroll', function() { if($(".layout-wrap").hasClass("homepage")){ navScroll();}});
  window.addEventListener('touchmove', function() { if($(".layout-wrap").hasClass("homepage")){ navScroll();}});


  function navScroll() {
    var sHeight = $(document).scrollTop();

    // 예를 들어, window.location.href는
    // http://localhost:3000/iFood-HomePage-askPersonally.html
    // siteUrl == /iFood-HomePage-askPersonally.html
    
    //let siteUrl = window.location.pathname;
    //// 메인 히어로 이미지가 있는 페이지 2개 -> home, service
    //let imgSite = (siteUrl == "/") ? true :
    //          (siteUrl == "/service/") ? true :
    //          false;

    // 모든 페이지 header에 scroll값 넣기
    //$('.layout-header').addClass('scroll');


    // 이미지 있는 사이트에서 특정 높이값에 따라 scroll값 유무 설정
    if(sHeight<60){
      //$('.layout-header').addClass('scroll-top');
      $('.layout-header').removeClass('scroll');
    }
    if(sHeight>=60){
      $('.layout-header').addClass('scroll');
      //$('.layout-header').removeClass('scroll-top');
    }
  }

  navSiteActive();
  function navSiteActive() {
    // 현재 사이트 주소
    let thisSite = window.location.href;
    // nav-time a 모든 값 살펴봐서
    for(let i=0; i<$navLink.length; i++) {
      let aHref = $navLink[i].href;
      // 만약 현재 사이트 url과 a href가 같으면 active-only
      if(thisSite == aHref) {
        $navLink[i].parentNode.classList.add('active-only');
        //$('.navbar-brand, .layout-header .navbar.mobile .navbar-nav .nav-item')[i].classList.add('active-only');
        //console.log($navLink[i]);
      }
    }
  }
  
}
// counter number
const stats = document.querySelectorAll(".counter");
stats.forEach(stat => {
	// pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
	const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?/;
	const time = 500;
	let result = [...patt.exec(stat.textContent)];
	let fresh = true;
	let ticks;

	// Remove first full match from result array (we dont need the full match, just the individual match groups).
	result.shift();
	// Remove undefined values from result array where they didnt have a match in one of the optional regex groups
	result = result.filter(res => res != null);

	while (stat.firstChild) {
		stat.removeChild(stat.firstChild);
	}

	for (let res of result) {
		if (isNaN(res)) {
			stat.insertAdjacentHTML("beforeend", `<span>${res}</span>`);
		} else {
			for (let i = 0; i < res.length; i++) {
				stat.insertAdjacentHTML(
					"beforeend",
					`<span data-value="${res[i]}">
						<span>0</span>
						<span>1</span>
						<span>2</span>
						<span>3</span>
						<span>4</span>
						<span>5</span>
						<span>6</span>
						<span>7</span>
						<span>8</span>
						<span>9</span>
						<span>0</span>
						${Array(parseInt(res[i]) + 1)
							.join(i)
							.split(i)
							.map( (x, j) => '<span>' + j + '</span>')
							.join("")}
					</span>`
				);
			}
		}
	}

	ticks = [...stat.querySelectorAll("span[data-value]")];

	let activate = () => {
		let top = stat.getBoundingClientRect().top;
		let offset = window.innerHeight * 0.8;

		setTimeout(() => {
			fresh = false;
		}, time);

		if (top < offset) {
			setTimeout(() => {
        for (let tick of ticks) {
          let dist = parseInt(tick.getAttribute("data-value")) + 11;
          tick.style.transform = `translateY(-${dist * 100}%)`;
        }
			}, fresh ? time : 0);
			window.removeEventListener("scroll", activate);
		}
	};
  
	window.addEventListener("scroll", activate);
	activate();
});


$('.mousey').on('click', function() {
  $("html, body").animate({ scrollTop: $(window).height() - 70}, 600);
  $('.layout-header').addClass('scroll');
  return false;
});


//pagination click 시 숫자 스타일 변경
function pagination() {
  $('.ta-pagination-wrap .page-item').on('click', function() {
    if($(this).find('.page-link').hasClass('next')) {
      let currentP = $('.ta-pagination-wrap .page-item.active');
      currentP.next().addClass('active');
      currentP.removeClass('active');

    } else if($(this).find('.√').hasClass('prev')) {
      let currentP = $('.ta-pagination-wrap .page-item.active');
      currentP.prev().addClass('active');
      currentP.removeClass('active');

    } else {
      $('.ta-pagination-wrap .page-item').removeClass('active');
      $(this).addClass('active');
    }
  });
}


/*function Boardevent() {
  $('.board-wrap .QnA').addClass('show');
  // 질문 카테고리 버튼
  $('.page-board .category .btn-category').on('click', function() {
    // 모든 답변 활성화 지우기
    $('.page-board .category .btn-category').removeClass('active');
    $(this).addClass('active');
    let target = this.dataset.target;

    // 혹시 열려있을 모든 질문의 답 가리기
    hideAnswer();
    // 타겟에 맞는 카테고리 질문 보이기
    if(target == "all") { // 카테고리-전체
      $('.board-wrap .QnA').addClass('show');
    }else {
      $('.board-wrap .QnA').removeClass('show');
      $(`.board-wrap .QnA.${target}`).addClass('show');
    }
  });*/

  // 질문 버튼
  /*$('.board-wrap .question').on('click', function() {
    let $answer = $(this).parent().find('.answer');
    console.log($answer);

    // 모든 질문들을 안보이게 숨김
    $('.board-wrap .question').addClass('hide');
    $('.board-wrap .ta-pagination-wrap').css('display', 'none');

    $answer.addClass('show');

    if($(window).width() < 768) {
      $('.qna-wrap .category').addClass('hide');
    }

    // 스크롤 맨 위로
    window.scrollTo(top);
  });

  // 답변에서 목록으로 버튼
  $('.board-wrap .answer .answer-back').on('click', function() {
    hideAnswer();
    $('.qna-wrap .category').removeClass('hide');
  });
*/

  // FAQ 뒤로가기 막기
  let blockBack = false;
  // 질문 누르고 들어가면 뒤로가기 다른 이벤트 작용
  $('.question').on('click', function() {
    blockBack = true;
    return blockBack;
  });
  // 다시 질문에서 나오면 뒤로가기 다른 이벤트 빼기
  $('.answer-back').on('click', function() {
    blockBack = false;
    return blockBack;
  });
  history.pushState(null, null, location.href);
  window.onpopstate = function () {
    if(blockBack == true) {
      history.go(1);
      window.location.reload();
    } else {
      window.history.back();
    }
  };


function hideAnswer() {
  $('.board-wrap .answer').removeClass('show');
  $('.board-wrap .question').removeClass('hide');
  $('.board-wrap .ta-pagination-wrap').css('display', 'block');
}


// a link anchor move + header nav 위치 고려
$('a[href^="#"]').on('click', function () {
  $('html, body').animate({
      scrollTop: $( $.attr(this, 'href') ).offset().top - 90
  }, 500);

  return false;
});


// images 모바일에서 드러그 못하게
$('img').on('dragstart', function(event) { event.preventDefault(); });


Utils.setCustomDropdownSelect();
